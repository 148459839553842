import React from "react"
import Card from "./card"
import { scale, options } from "../utils/typography"
import presets, { colors } from "../utils/presets"

const Demand = ({ demandData }) => {
  return (
      <>
          {demandData.frontmatter.requires.map((item, i) => (
            <Card key={i} overrideCSS={{
              maxWidth: `33.33333333%`,
              minWidth: `33.33333333%`,
              boxShadow: 'none',
              borderLeft: `1px solid ${colors.ui.light}`,
              "&:nth-child(3n+1)": {
                borderLeft: 0,
              },
              [presets.Tablet]: {
                maxWidth: `33.33333333%`,
                minWidth: `33.33333333%`,
              }
            }}>
                <h4 css={styles.cardHeadLine}>
                    {item.name}
                </h4>
                <p css={styles.futuraParagraph}>
                    {item.value}
                </p>
            </Card>
          ))}
          
          <div css={styles.demand} dangerouslySetInnerHTML={{ __html: demandData.html }}></div>
      </>
  )
}

const styles = {
    cardHeadLine: {
        ...scale(1 / 5),
        lineHeight: 1.2,
        marginTop: 0,
    },
    demand: {
        marginLeft: `0.85rem`,
        padding: `15px 0`,
    },
    futuraParagraph: {
        fontFamily: options.headerFontFamily.join(`,`),
        marginBottom: 0,
    },
}

export default Demand