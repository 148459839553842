import React from "react"
import { Carousel } from 'antd'
// import ecc1 from "../../images/technologyhighlights/ecc01.png"
// import ecc2 from "../../images/technologyhighlights/ecc02.png"
// import ecc3 from "../../images/technologyhighlights/ecc03.png"
// import ecc4 from "../../images/technologyhighlights/ecc04.png"
// import ecc5 from "../../images/technologyhighlights/ecc05.png"
// import ecc6 from "../../images/technologyhighlights/ecc06.png"
// import ecc7 from "../../images/technologyhighlights/ecc07.png"

const ScreenShot = (props) => (
    <Carousel css={styles.slick} autoplay>
      {props.images.map((image, i) => {
        return <div key={i}><img src={image.publicURL} alt="" /></div>
      })}
        {/* 
        <div><img src={ecc1} alt="" /></div>
        <div><img src={ecc2} alt="" /></div>
        <div><img src={ecc3} alt="" /></div>
        <div><img src={ecc4} alt="" /></div>
        <div><img src={ecc5} alt="" /></div>
        <div><img src={ecc6} alt="" /></div>
        <div><img src={ecc7} alt="" /></div>
        */}
    </Carousel>
)

const styles = {
    slick: {
        background: `dimGray`,
        overflow: `hidden`,
    },
}

export default ScreenShot