import React from "react"
import { Link } from 'gatsby'
import Card from "../components/card"
import { vP } from "../components/gutters"
import presets, { colors } from "../utils/presets"
import { rhythm, scale, options } from "../utils/typography"
// import Button from "./button";
import { StaticQuery, graphql } from "gatsby"

const Cards = ({
  location = {},
  overrideTitleCSS = {},
  overrideCardCSS = {}
}) => (
    <>
      <StaticQuery
        query={pageQuery}
        render={data => {
          const { edges: posts } = data.allMarkdownRemark
          return (
            <>
            {posts
              .map(({ node: post }) => {
                return (
                    <Card key={post.id} className="fadeCard fade-hidden animated" isLink={true} location={location} overrideCSS={{
                      "&:hover .card-icon": {
                        background: post.frontmatter.hoverIcon ? `url(${post.frontmatter.hoverIcon.publicURL}) no-repeat` : '',
                        backgroundSize: '30px 30px',
                      },
                      "&:hover a": {
                        color: "#fff",
                      }
                    }}>
                      <Link css={styles.link} to={post.frontmatter.link}>
                        { post.frontmatter.icon ? <div className="card-icon" css={{
                          height: 24,
                          marginBottom: 15,
                          background: `url(${post.frontmatter.icon.publicURL}) no-repeat`,
                          backgroundSize: '24px 24px',
                          [presets.Tablet]: {
                            marginBottom: 25,
                            height: 30,
                            backgroundSize: '30px 30px',
                          }
                        }}>
                        </div> : null }
                        <div className="card-info">
                          <h2 css={{...styles.cardHeadLine, ...overrideTitleCSS}}>
                              {post.frontmatter.title}
                          </h2>
                          {/* <Button small secondary overrideCSS={{marginBottom: `.6rem`, fontSize: '14px!important'}} to={post.frontmatter.link}>详情</Button> */}
                          <div css={{...styles.futuraParagraph, ...overrideCardCSS}} dangerouslySetInnerHTML={{ __html: post.html }}></div>
                        </div>
                      </Link>
                    </Card>
                )
              })
            }
            </>
          )
        }}
      />
    </>
)


const styles = {
    cardHeadLine: {
        ...scale(2 / 5),
        // lineHeight: 1.2,
        marginTop: 0,
        marginBottom: 15,
        // [presets.Tablet]: {
        //     fontSize: scale(1 / 10).fontSize,
        // },
        // [presets.Desktop]: {
        //     fontSize: scale(3 / 10).fontSize,
        // },
        // [presets.VHd]: {
        //     fontSize: scale(5 / 10).fontSize,
        // },
        // [presets.VVHd]: {
        //     fontSize: scale(7 / 10).fontSize,
        // },
    },
    Description: {
      margin: `0 auto ${rhythm(2)}`,
      maxWidth: rhythm(30),
    },
    futuraParagraph: {
        fontFamily: options.headerFontFamily.join(`,`),
        fontSize: 16,
        marginBottom: 0,
    },
    section: {
        borderRadius: presets.radiusLg,
        borderTop: `1px solid ${colors.ui.light}`,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        flex: `1 1 100%`,
        fontFamily: options.headerFontFamily.join(`,`),
        marginTop: rhythm(1),
        padding: vP,
        textAlign: `left`,
        [presets.Tablet]: {
            marginTop: 0,
        },
    },
    title: {
        ...scale(2 / 5),
        lineHeight: 1.2,
        marginTop: 0,
        textAlign: `center`,
        [presets.Tablet]: {
            fontSize: scale(1 / 10).fontSize,
        },
        [presets.Desktop]: {
            fontSize: scale(3 / 10).fontSize,
        },
        [presets.VHd]: {
            fontSize: scale(5 / 10).fontSize,
        },
        [presets.VVHd]: {
            fontSize: scale(7 / 10).fontSize,
        },
    },
    link: {
      display: 'block',
      color:' rgba(0, 0, 0, 0.88)',
      transition: 'color 0s',
    }
}

export default Cards

const pageQuery = graphql`
  query HomeCardsQuery {
    allMarkdownRemark(
      sort: {fields: [frontmatter___order], order:ASC }
      filter: { frontmatter: { type: { eq: "home" } } }
    ) {
      edges {
        node {
          excerpt
          id
          html
          frontmatter {
            title
            type
            link
            icon {
              publicURL
            }
          }
        }
      }
    }
  }
`
